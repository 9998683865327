import React from 'react'
import SplashPage from './SplashPage'
import SkillList from './SkillList'
import ProjectList from './ProjectList'

function Home() {
  return (
    <div>
        <SplashPage />
        {/* <SkillList /> */}
    </div>
  )
}

export default Home